import { FC, useState } from 'react';
import { Button, Stack, Typography, styled, CircularProgress } from '@mui/material';
import { ItemsBox, SaveControl } from 'shared/ui';
import { useAppSelector, useCatalogSearchParams } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import { CatalogAvailabilityOrgsTable, CatalogAvailabilityProducts } from 'features';
import { separateByUpperCase } from 'shared/lib';
import { AvailabilityGroup } from 'entities/Group/components';
import { SquareCheckBox } from 'shared/ui/checkBoxes';
import { CATALOG_TYPE } from 'shared/constants';
import { selectCatalogTypes } from '../../../shared/slices';
import { useAvailabilities } from '../hooks';

const BoxHeader = styled(Typography)(({ theme }) => ({
  padding: '4px 8px',
  fontWeight: 600,
  textTransform: 'capitalize',
  borderLeft: '1px solid grey',
  borderColor: theme.palette.info.light,
}));

const Item = styled(Button)(({ theme }) => ({
  padding: '5px !important',
  borderBottom: '1px solid grey',
  borderColor: theme.palette.info.light,
  color: 'black',
  textTransform: 'capitalize',
  justifyContent: 'flex-start',
  borderRadius: 0,
  '&:hover': { bgcolor: 'primary.light' },
}));

type BoxType = '' | 'org' | 'type' | 'groups' | 'products';

export const CatalogAvailability: FC = () => {
  const { t } = useTranslation();
  const catalogTypes = useAppSelector(selectCatalogTypes);

  const { catalogId } = useCatalogSearchParams();

  const {
    type,
    handleChangeType,
    viewGroups,
    viewProducts,
    isAvailabilitiesLoading,
    catalogInfo,
    onChangeCurrentOrg,
    openAvailabilityView,
    isAvailabilityHasChanges,
    isLoading,
    viewGroupID,
    selectedGroupIDs,
    selectedProductIDs,
    selectedViewGroupsAmount,
    selectedViewProductsAmount,
    onChangeViewGroupID,
    onToggleAllGroupsSelected,
    onToggleGroup,
    onToggleAllProductsSelected,
    onToggleProduct,
    isAvailabilitiesSaving,
    onSaveAvailability,
    onCancelClick,
  } = useAvailabilities();

  const [activeBoxes, setActiveBoxes] = useState<{ [catalogId: string]: BoxType }>({});
  const currentCatalog = catalogInfo[catalogId];

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack direction="row" spacing={4} alignItems="flex-start" sx={{ height: '100%' }}>
        <CatalogAvailabilityOrgsTable
          isLoading={isAvailabilitiesLoading}
          currentOrgId={currentCatalog?.currentOrgId}
          onChangeCurrentOrgId={(id) => {
            onChangeCurrentOrg(id);
            setActiveBoxes((prev) => ({ ...prev, [catalogId]: 'org' }));
          }}
          onOpenAvailability={() => openAvailabilityView(currentCatalog.currentOrgId)}
          isOpenBtn={currentCatalog?.isAddButton}
          isActiveTable={activeBoxes[catalogId] === 'org'}
        />

        {currentCatalog?.isAvailabilityView && !isAvailabilitiesLoading && (
          <>
            <ItemsBox
              header={<BoxHeader>{t('Select Type')}</BoxHeader>}
              isActive={activeBoxes[catalogId] === 'type'}
              sx={{ maxHeight: 'calc(100svh - 310px)' }}
            >
              {catalogTypes.map((t) => (
                <Item
                  key={t}
                  onClick={() => {
                    handleChangeType(t);
                    setActiveBoxes((prev) => ({ ...prev, [catalogId]: 'type' }));
                  }}
                  sx={{
                    p: 1,
                    ...(type === t && {
                      bgcolor: 'primary.light',
                      '&:hover': { bgcolor: 'primary.light' },
                    }),
                    ...(t === CATALOG_TYPE.DISTRIBUTION_CURVE && { display: 'none' }),
                  }}
                >
                  {separateByUpperCase(t)}
                </Item>
              ))}
            </ItemsBox>

            <ItemsBox
              header={
                <Stack direction="row">
                  <SquareCheckBox
                    checked={!!selectedViewGroupsAmount && selectedViewGroupsAmount === viewGroups.length}
                    onToggle={() => {
                      onToggleAllGroupsSelected();
                      setActiveBoxes((prev) => ({ ...prev, [catalogId]: 'groups' }));
                    }}
                  />
                  <BoxHeader>{t('Select Groups')}</BoxHeader>
                </Stack>
              }
              isActive={activeBoxes[catalogId] === 'groups'}
              sx={{ maxHeight: 'calc(100svh - 310px)', width: '100%' }}
            >
              {viewGroups.length ? (
                viewGroups.map((g) => {
                  const products = g.availableComponents;
                  const selectedProducts = products.filter((p) => selectedProductIDs.includes(p.id));

                  return (
                    <AvailabilityGroup
                      key={g.id}
                      group={g}
                      checked={selectedGroupIDs.includes(g.id)}
                      onToggle={(id) => {
                        onToggleGroup(id);
                        setActiveBoxes((prev) => ({ ...prev, [catalogId]: 'groups' }));
                      }}
                      disabled={isLoading}
                      selectedItemsAmount={selectedProducts.length}
                      itemsAmount={products.length}
                      isSelectedForView={g.id === viewGroupID}
                      onSelectForView={(id) => {
                        onChangeViewGroupID(id);
                        setActiveBoxes((prev) => ({ ...prev, [catalogId]: 'groups' }));
                      }}
                      isLoading={isLoading}
                    />
                  );
                })
              ) : (
                <Stack alignItems={'center'} sx={{ height: '34px' }}>
                  {isLoading ? (
                    <CircularProgress size={15} color="primary" sx={{ m: '9px' }} />
                  ) : (
                    <Typography sx={{ p: '5px', textAlign: 'center' }}>No Groups</Typography>
                  )}
                </Stack>
              )}
            </ItemsBox>

            <CatalogAvailabilityProducts
              isLoading={isLoading}
              selectedViewProductItemsAmount={selectedViewProductsAmount}
              viewProducts={viewProducts}
              selectedProductIDs={selectedProductIDs}
              onToggleAll={() => {
                onToggleAllProductsSelected();
                setActiveBoxes((prev) => ({ ...prev, [catalogId]: 'products' }));
              }}
              onToggleProduct={(id) => {
                onToggleProduct(id);
                setActiveBoxes((prev) => ({ ...prev, [catalogId]: 'products' }));
              }}
              isActiveTable={activeBoxes[catalogId] === 'products'}
            />
          </>
        )}
      </Stack>

      {currentCatalog?.isAvailabilityView && isAvailabilityHasChanges && (
        <Stack alignItems={'flex-end'} sx={{ py: '25px' }}>
          <SaveControl
            onSave={onSaveAvailability}
            onCancel={onCancelClick}
            btnTexts={['Save Changes']}
            isLoading={isAvailabilitiesSaving}
          />
        </Stack>
      )}
    </Stack>
  );
};
