export enum AppType {
  Platform,
  HydraulicTool,
  Rapid,
  Pro,
}

export enum ROUTE {
  LOGIN = '/common/login',
  SIGN_UP = '/common/sign-up',
  FORGOT_PASSWORD = '/common/forgot-password',
  ACTIVATION = '/common/activation',
  RESET_PASSWORD = '/common/reset-password',

  HOME = '/main/home',
  ORGANIZATIONS = '/main/organizations',
  CATALOGS = '/main/product-catalogs',
  USER_MANAGEMENT = '/main/users-management',
}

export enum CatalogPermission {
  None = 'None',
  Read = 'Read',
  Write = 'Write',
  Share = 'Share',
}
