import { IconButton, Popover, Stack } from '@mui/material';
import {
  GridEditInputCell,
  GridRenderEditCellParams,
  useGridApiContext,
  useGridApiEventHandler,
} from '@mui/x-data-grid-premium';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useAnchorElement, useAppDispatch, useCatalogsControl } from 'shared/hooks';
import { Icon } from 'shared/ui';
import { KeyboardEvent, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { CellType, TableProductInfo } from 'shared/models';
import { addCellError, changeProductItem, removeCellError } from 'shared/slices';

import { getProductsMap, validateCellValue } from 'shared/lib';

export const RenderEditCell = (params: GridRenderEditCellParams) => {
  const { id, field, api, colDef, row } = params;

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();

      const allRowIds = api.getAllRowIds();
      const rowIndex = api.getRowIndexRelativeToVisibleRows(row.id);

      if (event.key === 'ArrowUp') {
        api.stopCellEditMode({ id, field });

        const nextRowId = allRowIds[rowIndex - 1];
        if (!nextRowId) return;

        api.setCellFocus(nextRowId, field);
      } else if (event.key === 'ArrowDown') {
        api.stopCellEditMode({ id, field });

        const nextRowId = allRowIds[rowIndex + 1];
        if (!nextRowId) return;

        api.setCellFocus(nextRowId, field);
      }
    }
  };

  return (
    <CellContainer params={params}>
      <GridEditInputCell
        {...params}
        error={!!params.error}
        inputProps={{
          min: 0,
          width: `${colDef.width}px`,
          onKeyDown: handleKeyDown,
        }}
      />
    </CellContainer>
  );
};

interface Props extends PropsWithChildren {
  params: GridRenderEditCellParams;
}

const CellContainer = ({ children, params }: Props) => {
  const { id, field, error, colDef, value } = params;

  const dispatch = useAppDispatch();
  const apiRef = useGridApiContext();
  const { open, anchorEl, onOpenMenu, onCloseMenu } = useAnchorElement();
  const {
    catalogId,
    items,
    deletedItemIDs,
    cellErrors,
    visibleItems,
    changedItems,
    isDistributionCurveType,
    allComponents,
  } = useCatalogsControl();

  const initial = visibleItems[id]
    ? (visibleItems[id][field as keyof TableProductInfo] as string | number | null)
    : null;
  const initialValue = colDef.type === 'number' && !initial ? '' : initial;

  const isCorrectInitValue = !validateCellValue(
    id.toString(),
    field,
    initialValue?.toString() ?? '',
    getProductsMap(items),
    deletedItemIDs,
    isDistributionCurveType,
    changedItems,
    allComponents
  );

  const openedValue = useRef(value?.toString() ?? '');

  const [errorMessage, setErrorMessage] = useState(error);

  useEffect(() => {
    const error = validateCellValue(
      id.toString(),
      field,
      value,
      getProductsMap(items),
      deletedItemIDs,
      isDistributionCurveType,
      changedItems,
      allComponents
    );

    setErrorMessage(error);
  }, [error]);

  const handleRevertValue = () => {
    apiRef.current.setEditCellValue({ id, field, value: initialValue });
    const value = colDef.type === 'number' && initialValue === '' ? null : initialValue;

    const cell: CellType = { id: id.toString(), field, value };
    catalogId && dispatch(removeCellError({ catalogId, cell }));
    catalogId && dispatch(changeProductItem({ catalogId, cell }));
  };

  const handleFinishEditing = () => {
    const cell: CellType = { id: id.toString(), field, value };
    if (errorMessage) {
      dispatch(addCellError({ catalogId, cell }));
      return;
    } else {
      cellErrors[id]?.[field] && dispatch(removeCellError({ catalogId, cell }));
    }

    if (openedValue.current === value) return;

    let updatedValue = typeof value === 'string' ? value.trim() : value;
    updatedValue = colDef.type === 'number' && value === '' ? null : updatedValue;

    const updatedCell: CellType = { id: id.toString(), field, value: updatedValue };
    catalogId && dispatch(changeProductItem({ catalogId, cell: updatedCell }));
  };

  useGridApiEventHandler(apiRef, 'cellEditStop', handleFinishEditing);

  return (
    <Stack
      direction="row"
      sx={{
        width: `${colDef.width}px`,
        height: '30px',
        border: '2px solid black',
        borderColor: errorMessage ? 'red' : 'black',
        py: '2px',
        pl: '6px',
        pr: '3px',
      }}
    >
      {children}

      {!!errorMessage && (
        <Stack direction="row">
          {isCorrectInitValue && (
            <IconButton onClick={handleRevertValue} sx={{ p: '3px' }}>
              <Icon path="error-revert" sx={{ width: '14px' }} />
            </IconButton>
          )}

          <IconButton onClick={onOpenMenu} sx={{ p: '0px' }}>
            <Icon path="error-help" sx={{ width: '14px' }} />
          </IconButton>
        </Stack>
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ '& .MuiPaper-root': { borderRadius: '6px' }, mt: 1 }}
      >
        <Stack
          alignItems={'center'}
          sx={{ textAlign: 'center', maxWidth: '230px', color: 'error.main', fontSize: 14, p: 1, pt: 0 }}
        >
          <PlayArrowIcon sx={{ height: '16px', rotate: '90deg', color: 'error.main' }} />

          {errorMessage}
        </Stack>
      </Popover>
    </Stack>
  );
};
