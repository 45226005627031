import { TabItem } from 'shared/models';
import { GridScrollParams } from '@mui/x-data-grid-premium';

export const localStorageService = {
  get transferEmailSuggestions() {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    return localStorage.getItem('transfer-email-suggestions')!;
  },

  set transferEmailSuggestions(suggestions: string) {
    localStorage.setItem('transfer-email-suggestions', suggestions);
  },

  get userId() {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    return localStorage.getItem('userId')!;
  },
  get userName() {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    return localStorage.getItem('name')!;
  },

  get tabs() {
    return JSON.parse(localStorage.getItem('tabs') ?? '[]');
  },

  set tabs(tabs: TabItem[]) {
    localStorage.setItem('tabs', JSON.stringify(tabs));
  },

  get catalogsColumnWidths() {
    return JSON.parse(localStorage.getItem('catalogsColumnWidths') ?? '{}');
  },

  set catalogsColumnWidths(columns: { [id: string]: { [column: string]: number } }) {
    localStorage.setItem('catalogsColumnWidths', JSON.stringify(columns));
  },

  get lastDcCatalogIDs() {
    return JSON.parse(localStorage.getItem('lastDcCatalogIDs') ?? '[]');
  },

  set lastDcCatalogIDs(ids: string[]) {
    localStorage.setItem('lastDcCatalogIDs', JSON.stringify(ids));
  },

  set lastTableScrollPosition(scroll: Record<string, GridScrollParams>) {
    localStorage.setItem('scroll', JSON.stringify(scroll));
  },

  get lastTableScrollPosition() {
    return JSON.parse(localStorage.getItem('scroll') || '{}');
  },
};
