import { MutableRefObject, useEffect } from 'react';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { GridScrollParams } from '@mui/x-data-grid-premium';
import { localStorageService } from '../services';

export const usePersistTableScroll = (tableRef: MutableRefObject<GridApiPremium>, catalogId = 'default') => {
  // Save table scroll in local storage
  useEffect(() => {
    let lastExecuted = Date.now();
    let scrollPosition: GridScrollParams;
    const unsubscribe = tableRef.current.subscribeEvent('scrollPositionChange', () => {
      // throttle for 700ms variable rewriting
      if (Date.now() > lastExecuted + 700) {
        lastExecuted = Date.now();
        scrollPosition = tableRef.current?.getScrollPosition();
      }
    });
    return () => {
      if (!scrollPosition) return;
      let storedScroll = localStorageService.lastTableScrollPosition;
      storedScroll = { ...storedScroll, [catalogId]: scrollPosition };
      localStorageService.lastTableScrollPosition = storedScroll;
      unsubscribe();
    };
  }, []);

  // Restore table scroll from local storage
  useEffect(() => {
    let timerId: NodeJS.Timeout;
    const scroll: Record<string, GridScrollParams> | null = localStorageService.lastTableScrollPosition;
    if (scroll !== null && scroll[catalogId]) {
      /* use setTimeout because when component mounts tableRef is not initialized yet */
      timerId = setTimeout(() => {
        tableRef.current.scroll(scroll[catalogId]);
      }, 5); // 5ms timeout because when it is set to 0 then horizontal scroll does not work
    }
    return () => {
      timerId ? clearTimeout(timerId) : null;
    };
  }, [tableRef]);
};
