import {
  CreatedCurveDTO,
  DeletedCurveDTO,
  DistributionCurvePlace,
  FullSpecificationsProductInfo,
  TableProductInfo,
  UpdatedCurveDTO,
} from 'shared/models';
import { sortProducts } from './productsParsing';
import { CURVE_PLACES_AMOUNT, ORIENTATION, PRODUCT_KEY } from 'shared/constants';

export const sortDistributionCurves = (items: TableProductInfo[], field: string, sorting: string) => {
  const emitterIDs = [...new Set(items.map((c) => c.fatherEmitterId))];

  let sortedCurves: TableProductInfo[] = [];

  emitterIDs.forEach((emId) => {
    const curves = items
      .filter((c) => c.fatherEmitterId === emId)
      .sort((p1, p2) => sortProducts(p1, p2, field, sorting));

    sortedCurves = [...sortedCurves, ...curves];
  });

  return sortedCurves;
};

export const getDistributionCurves = (fetchedProducts: FullSpecificationsProductInfo[]) => {
  const curves: TableProductInfo[] = [];

  fetchedProducts.forEach((product) => {
    const edcProfiles = product.component?.emitter?.edcProfiles ?? [];

    edcProfiles &&
      edcProfiles.forEach((edc) => {
        const places = [...new Array(CURVE_PLACES_AMOUNT)]
          .map((_, i) => ({
            [`place${i}`]: edc.places[i] /* ?? 1 */,
          }))
          .reduce((acc, c) => ({ ...acc, ...c }), {});

        const uprightNumber = 0;

        const item: TableProductInfo = {
          id: edc.id,
          fatherEmitterId: product.component.id,

          sku: product.component.sku,
          description: product.component.description,
          brand: product.component?.brand?.name ?? null,
          subtype: product.group?.subtype?.name ?? null,
          group: product.group.name,
          groupId: product.group.id,

          nozzle: product.component.emitter.nozzle ?? null,
          swivel: product.component.emitter.swivel ?? null,

          orient: edc.orient === uprightNumber ? ORIENTATION.UPRIGHT : ORIENTATION.INVERTED,
          pressure: edc.pressure,
          riser: edc.riser,
          basedis: edc.basedis,
          flowRate: edc.flowRate,
          range: edc.range,
          ...places,
        };

        curves.push(item);
      });
  });

  return curves;
};

export const getNewDistributionCurve = (item: TableProductInfo) => {
  const places = [...new Array(CURVE_PLACES_AMOUNT)]
    .map((_, i) => ({ [`place${i}`]: null }))
    .reduce((acc, c) => ({ ...acc, ...c }), {});

  const curve: TableProductInfo = {
    fatherEmitterId: item.id,
    sku: item.sku,
    description: item.description,
    subtype: null,
    brand: item.brand,
    group: item.group,
    groupId: item.groupId,

    nozzle: item.nozzle,
    swivel: item.swivel,

    id: Math.random().toString(),
    orient: ORIENTATION.UPRIGHT as string,
    riser: null,
    pressure: null,
    basedis: null,
    flowRate: null,
    range: null,
    ...places,
  };

  return curve;
};

export const checkIsCurveCellDisabled = (field: string) => {
  const cells: string[] = [
    PRODUCT_KEY.SKU,
    PRODUCT_KEY.DESC,
    PRODUCT_KEY.BRAND,
    PRODUCT_KEY.SUBTYPE,
    PRODUCT_KEY.GROUP,
    PRODUCT_KEY.NOZZLE,
    PRODUCT_KEY.SWIVEL,
  ];
  return cells.includes(field);
};

export const getCurveTopComponentIDs = (items: TableProductInfo[]) => {
  const topComponentIDs: string[] = [];
  const fatherEmitterIDs: string[] = [];

  items.forEach((c) => {
    if (!fatherEmitterIDs.includes(c?.fatherEmitterId as string)) {
      fatherEmitterIDs.push(c?.fatherEmitterId as string);
      topComponentIDs.push(c.id);
    }
  });

  return topComponentIDs;
};

const getDeletedCurvesDTOs = (deletedItemIDs: string[], items: TableProductInfo[]) => {
  const deletedItems = items.filter((p) => deletedItemIDs.includes(p.id));

  const componentIds = [...new Set(deletedItems.map((p) => p.fatherEmitterId as string))];

  const DTOs: DeletedCurveDTO[] = componentIds.map((componentId) => {
    const items = deletedItems.filter((p) => p.fatherEmitterId === componentId);

    const groupId = items[0].groupId as string;
    const edcProfilesId = items.map((p) => p.id);

    const dto: DeletedCurveDTO = { groupId, componentId, edcProfilesId };

    return dto;
  });

  return DTOs;
};

const getNewCurvesDTOs = (newItemIDs: string[], items: TableProductInfo[]) => {
  const newItems = items.filter((p) => newItemIDs.includes(p.id));

  const componentIds = [...new Set(newItems.map((p) => p.fatherEmitterId as string))];

  const DTOs: CreatedCurveDTO[] = componentIds.map((componentId) => {
    const items = newItems.filter((p) => p.fatherEmitterId === componentId);

    const groupId = items[0].groupId as string;

    const requestParameters = items.map((p) => {
      const places = [...new Array(CURVE_PLACES_AMOUNT)]
        .map((_, i) => {
          const key = `place${i}` as keyof DistributionCurvePlace;
          return p[key] ?? 0;
        })
        .filter((value) => value !== undefined);

      const uprightNumber = 0;
      const invertedNumber = 1;

      return {
        orient: p.orient === ORIENTATION.UPRIGHT ? uprightNumber : invertedNumber,
        riser: p.riser as number,
        pressure: p.pressure as number,
        basedis: p.basedis as number,
        flowRate: p.flowRate as number,
        range: p.range as number,
        places,
      };
    });

    const dto: CreatedCurveDTO = { groupId, componentId, requestParameters };

    return dto;
  });

  return DTOs;
};

const getChangedCurvesDTOs = (changes: TableProductInfo[], items: TableProductInfo[]) => {
  const curveIDs = changes.map((c) => c.id);
  const changedProducts = items.filter((c) => curveIDs.includes(c.id));

  const componentIds = [...new Set(changedProducts.map((p) => p.fatherEmitterId as string))];

  const DTOs: UpdatedCurveDTO[] = componentIds.map((componentId) => {
    const items = changedProducts.filter((p) => p.fatherEmitterId === componentId);

    const groupId = items[0].groupId as string;

    const updateParameters = items.map((p) => {
      const places = [...new Array(CURVE_PLACES_AMOUNT)]
        .map((_, i) => {
          const key = `place${i}` as keyof DistributionCurvePlace;
          return p[key] ?? 0;
        })
        .filter((value) => value !== undefined);

      const uprightNumber = 0;
      const invertedNumber = 1;

      const edcProfileParameters = {
        orient: p.orient === ORIENTATION.UPRIGHT ? uprightNumber : invertedNumber,
        riser: p.riser as number,
        pressure: p.pressure as number,
        basedis: p.basedis as number,
        flowRate: p.flowRate as number,
        range: p.range as number,
        places,
      };

      return { edcProfileId: p.id, edcProfileParameters };
    });

    const dto: UpdatedCurveDTO = { groupId, componentId, updateParameters };

    return dto;
  });

  return DTOs;
};

export const getDistributionCurveDTOs = (params: {
  items: TableProductInfo[];
  changedItems: { [id: string]: TableProductInfo };
  newItemIDs: { [id: string]: boolean };
  deletedItemIDs: { [id: string]: boolean };
}) => {
  const { items, changedItems, newItemIDs, deletedItemIDs } = params;

  const deletedCurves = getDeletedCurvesDTOs(Object.keys(deletedItemIDs), items);
  const newCurves = getNewCurvesDTOs(Object.keys(newItemIDs), items);

  const changes = Object.values(changedItems).filter(({ id }) => !newItemIDs[id] && !deletedItemIDs[id]);
  const changedCurves = getChangedCurvesDTOs(changes, items);

  return { deletedCurves, newCurves, changedCurves };
};
