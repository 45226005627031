import { useNavigate } from 'react-router-dom';
import * as process from 'process';
import { ROUTE } from 'shared/enum';
import { CATALOG_TYPE } from 'shared/constants';

export const useAppNavigator = () => {
  const navigate = useNavigate();

  const navigateToLogin = () => navigate(ROUTE.LOGIN);
  const navigateToHome = () => navigate(ROUTE.HOME);
  const navigateToPreviousRoute = () => navigate(-1);
  const navigateToOrganizations = () => navigate(ROUTE.ORGANIZATIONS, { replace: true });
  const navigateToCurrentOrganization = (id: number) => navigate(`${ROUTE.ORGANIZATIONS}/${id}`);
  const navigateToUsersManagement = () => navigate(ROUTE.USER_MANAGEMENT);
  const navigateToCatalogs = () => navigate(ROUTE.CATALOGS);
  const navigateToCurrentCatalog = (id: string) => navigate(`${ROUTE.CATALOGS}/${id}?type=${CATALOG_TYPE.PIPE}`);
  const navigateToCatalogManagement = (id: string) => navigate(`${ROUTE.CATALOGS}/${id}/management?manageIndex=0`);

  const navigateToRapidApp = () => {
    window.location.href = process.env.REACT_APP_RAPID_URL;
  };
  const navigateToFieldRapidIrrigationApp = () => {
    window.location.href = process.env.REACT_APP_FIELD_RAPID_IRRIGATION_URL;
  };
  const navigateToProRapidIrrigationApp = () => {
    window.location.href = process.env.REACT_APP_PRO_RAPID_IRRIGATION_URL;
  };

  const navigateToHTApp = () => {
    window.location.href = process.env.REACT_APP_HT_URL;
  };

  return {
    navigate,
    navigateToHTApp,
    navigateToRapidApp,
    navigateToLogin,
    navigateToHome,
    navigateToPreviousRoute,
    navigateToOrganizations,
    navigateToCurrentOrganization,
    navigateToCatalogs,
    navigateToCurrentCatalog,
    navigateToCatalogManagement,
    navigateToUsersManagement,
    navigateToFieldRapidIrrigationApp,
    navigateToProRapidIrrigationApp,
  };
};
