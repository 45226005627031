import { Box, Stack } from '@mui/material';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useCatalogSearchParams } from 'shared/hooks';
import { getDistributionCurveDTOs, getProductsSaveBulk, makeDeepValidation } from 'shared/lib';
import { ActionType, Brand, Group, TableProductInfo } from 'shared/models';
import {
  saveDistributionCurvesThunk,
  selectOpenCatalogFinalUpdates,
  setCellErrors,
  setSaveBulk,
  toggleCatalogFinalUpdates,
  toggleCatalogSavingStatus,
  toggleValidating,
} from 'shared/slices';
import { Button, DialogModal } from 'shared/ui';

type Props = {
  type: string;
  items: TableProductInfo[];
  changedItems: { [id: string]: TableProductInfo };
  deletedItemIDs: { [id: string]: boolean };
  newItemIDs: { [id: string]: boolean };
  isDistributionCurveType: boolean;
  actionHistory: ActionType[];
  cellErrors: { [id: string]: { [key: string]: boolean } };
  groups: Group[];
  brands: Brand[];
  allComponents: TableProductInfo[];
};

export const CatalogFinalUpdates: FC<Props> = ({
  type,
  items,
  deletedItemIDs,
  newItemIDs,
  changedItems,
  isDistributionCurveType,
  cellErrors,
  groups,
  brands,
  allComponents,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { catalogId } = useCatalogSearchParams();

  const openCatalogFinalUpdates = useAppSelector(selectOpenCatalogFinalUpdates);
  const onClose = () => dispatch(toggleCatalogFinalUpdates());

  const deletedItems = items.filter(({ id }) => deletedItemIDs[id]);
  const createdItems = items.filter(({ id }) => newItemIDs[id]);
  const updatedItems = items.filter(({ id }) => changedItems[id] && !newItemIDs[id] && !deletedItemIDs[id]);

  const allItems = [...createdItems, ...deletedItems, ...updatedItems];

  const gridItems = allItems.map((p) => {
    const { id, sku, description } = p;
    let action = '';
    if (changedItems[id]) action = 'Updated';
    if (deletedItemIDs[id]) action = 'Deleted';
    if (newItemIDs[id]) action = 'Created';

    return { id, sku, description, action };
  });

  const columns: GridColDef[] = [
    {
      field: 'sku',
      headerName: t('sku'),
      type: 'string',
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      width: 150,
    },
    {
      headerName: t('description'),
      field: 'description',
      type: 'string',
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      width: 500,
    },
    {
      headerName: t('Action'),
      field: 'action',
      type: 'string',
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      width: 150,
    },
  ];

  const handSaveChanges = async () => {
    const hasErrors = !!Object.keys(cellErrors ?? {}).length;

    const errors = makeDeepValidation(
      items,
      deletedItemIDs,
      isDistributionCurveType,
      changedItems,
      allComponents,
      groups
    );

    if (errors || hasErrors) {
      errors && dispatch(setCellErrors({ catalogId, cellErrors: errors }));
      dispatch(toggleValidating(catalogId));

      onClose();
      return;
    }

    if (isDistributionCurveType) {
      const curveOptions = { items, changedItems, newItemIDs, deletedItemIDs };
      const { newCurves, changedCurves, deletedCurves } = getDistributionCurveDTOs(curveOptions);

      await dispatch(saveDistributionCurvesThunk({ catalogId, newCurves, changedCurves, deletedCurves }));

      onClose();

      return;
    }

    const bulkOptions = { type, items, newItemIDs, deletedItemIDs, changedItems, groups, brands };
    const { bulk, movedProductGroups } = getProductsSaveBulk(bulkOptions);
    dispatch(setSaveBulk({ catalogId, bulk, movedProductGroups }));

    onClose();
    dispatch(toggleCatalogSavingStatus());
  };

  return (
    <DialogModal
      open={openCatalogFinalUpdates}
      onClose={onClose}
      padding={'24px'}
      sx={{
        '& .MuiPaper-root': {
          width: '900px',
          maxWidth: '900px',
        },
      }}
    >
      <Stack alignItems="center">
        <DataGridPremium
          rowHeight={56}
          hideFooter
          columns={columns}
          rows={gridItems}
          disableColumnResize
          disableRowSelectionOnClick
          disableColumnReorder
          sx={{
            minHeight: 0,
            width: '100%',
            maxHeight: '70vh',
            overflowX: 'hidden',
            p: 0,
            border: 'none',
            borderRadius: 0,
            mb: 2,
            '& .MuiDataGrid-cell': {
              padding: '0 1',
              fontSize: 16,
              borderBottom: '1px solid grey',
            },
            '& .MuiDataGrid-columnHeader': {
              fontSize: 16,
              borderBottom: 'none',
              '*': { fontWeight: 600 },
            },
            '& .MuiDataGrid-virtualScroller': {
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'primary.main',
                borderRadius: '30px',
                height: '10px !important',
              },
              '&::-webkit-scrollbar': {
                width: '10px',
                height: '10px !important',
                backgroundColor: 'info.light',
              },
              '&::-webkit-scrollbar-track': {
                width: '10px',
                height: '10px !important',
                backgroundColor: 'info.light',
              },
            },
            '& .MuiDataGrid-row.Mui-hovered, & .MuiDataGrid-row:hover': {
              backgroundColor: 'transparent !important',
            },
            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': { outline: 'none !important' },
          }}
        />

        <Box sx={{ height: '40px' }}>
          <Button onClick={handSaveChanges} filled>
            {t('Save Changes')}
          </Button>
        </Box>
      </Stack>
    </DialogModal>
  );
};
