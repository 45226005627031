export const DRAWER_OPEN_WIDTH = 244;
export const DRAWER_CLOSED_WIDTH = 55;

export type MenuItem = {
  title: string;
  route: string;
  icon: string;
  callback?: () => void;
};

export const BOTTOM_MENU_ITEMS: MenuItem[] = [
  {
    title: 'recent',
    route: '/main/recent',
    icon: 'menuIcons/recent',
  },
  {
    title: 'shared_with_me',
    route: '/main/shared',
    icon: 'menuIcons/shared',
  },
];
